import whatsapp from "../images/services/whatsapp.png";
import sms from "../images/services/sms.png";
import digital from "../images/services/digital.png";
import web from "../images/services/web.png";



export const services=[

    
    {
        image:whatsapp,
        heading:"Whatsup API",
        details:"WhatsApp API integration services for businesses looking to enhance customer communication"

    },
    {
        image:sms,
        heading:"Bulk SMS",
        details:"Reach thousands or even millions of recipients instantly with our bulk SMS campaigns"
        
    },
    {
        image:digital,
        heading:"Digital Marketing",
        details:"Grow your business with Digital Marketing, Social Media, YouTube, Email Marketing, SEO."
        
    },
    {
        image:web,
        heading:"Website Development",
        details:"Focus on your core business while we handle your IT operations with technical environment."
        
    }
 
]