import React from 'react';
import "./Preloader.css";
import logo from "../../images/logo.png"

const Preloder = () => {
  return (

    <div className='preloading'>
    <div className='preloader mx-auto '>
        <div className='content mx-auto'>
  <img src={logo} alt="preloader" className='img-fluid'/> 
        </div>
       
    </div>
    </div>
  )
}

export default Preloder