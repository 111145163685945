import React, { useState } from "react";
import "./Product.css";
import Contact from "../contact/Contact";
import { product } from "../../data/productData";
import leftarrow from "../../images/leftArrow.png";
import rightArrow from "../../images/rightArrow.png";
import { motion } from "framer-motion";
const Product = () => {
  const [selected, setSelected] = useState(0);
  const productLength = product.length;
  const tranisition ={type:"spring",duration:3}
  return (
    <>
    <div className="productsession" id="productsession">
      <div className="left-product">
        <span>Portfolio</span>
        <span className="stroke-text">Our</span>
        <span>Products</span>
        <span style={{ color: "var(--green)" }}>{product[selected].title}</span>
        <motion.span
        key={selected}
        initial={{opacity:0,x:-100}}
        animate={{opacity:1,x:0}}
        exit={{opacity:0,x:100}}
        transition={tranisition}
        >{product[selected].detail}
        </motion.span>
      </div>
      <div className="right-product">
        <motion.div
        initial={{opacity:0,x:-100}}
        transition={{...tranisition,duration:2}}
        whileInView={{opacity:1,x:0}}
        ></motion.div>
          <motion.div
        initial={{opacity:0,x:100}}
        transition={{...tranisition,duration:2}}
        whileInView={{opacity:1,x:0}}
        ></motion.div>
        <motion.img
        key={selected}
        initial={{opacity:0,x:100}}
        animate={{opacity:1,x:0}}
        exit={{opacity:0,x:-100}}
        transition={tranisition}
          src={product[selected].image}
          alt="productimage"
          className="img-fluid"
        />
        <div className="arrows">
          <img
            onClick={() => {
              selected === 0
                ? setSelected(productLength - 1)
                : setSelected((prev) => prev - 1);
            }}
            src={leftarrow}
            alt="leftarrow"
            className="img-fluid"
          />
          <img
            onClick={() => {
              selected === productLength - 1
                ? setSelected(0)
                : setSelected((prev) => prev + 1);
            }}
            src={rightArrow}
            alt="rightarrow"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
    <Contact/>
    </>
  );
};

export default Product;
