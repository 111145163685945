import React ,{useState,useEffect}from "react";
import "./ScrolltoTop.css"
function ScrolltoTop(){
    const [isVisible,setIsVisible]=useState(false);
    const goToBtn=()=>{
     window.scrollTo({top:0,left:0,behavior:"smooth"})
    }

    const listenToScroll =()=>{
        let heightToHidden = 20;
        const winScroll =document.body.scrollTop ||document.documentElement.scrollTop;

        if(winScroll>heightToHidden){
            setIsVisible(true);
        }else{
            setIsVisible(false);
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll",listenToScroll);
        return()=>window.removeEventListener("scroll",listenToScroll)
    },[])
    
return(
     <div className="scrolltotop">
{isVisible && (

    <div className="top-btn"  onClick={goToBtn}>
    <i class="fa fa-arrow-up" aria-hidden="true" style={{color:"whi"}}></i>
  
</div>
)}
  
    </div>
  
   
)
}

export default ScrolltoTop;