import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap"; // <-- JS File;
import AOS from "aos";
import "aos/dist/aos.css";
import Hero from "./component/hero/Hero";
import Productdetail from "./component/productdetail/Productdetail";
import ServiceDetail from "./component/servicedetail/ServiceDetail";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Career from "./component/career/Career";
import Services from "./component/services/Services";
import Contact from "./component/contact/Contact";
import Product from "./component/product/Product";
import About from "./component/About/About";
import Preloder from "./component/preloader/Preloder";
import ScrolltoTop from "./component/scrolltotop/ScrolltoTop";
function App() {
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    setTimeout(() => {
      setisLoading(false);
    }, 4000);
  }, []);
  return isLoading ? (
    <Preloder />
  ) : (
    <div className="App">
      <BrowserRouter>
        <ScrolltoTop />
        <Routes>
          <Route path="/" element={<Hero />}></Route>
          <Route path="/productdetail" element={<Productdetail />}></Route>
          <Route path="/servicedetail" element={<ServiceDetail />}></Route>
          <Route path="/career" element={<Career />} />
          <Route path="/product" element={<Product />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/services" element={<Services />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
