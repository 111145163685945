import React from 'react';
import "./Services.css";
import About from '../About/About';
import rightarrow from "../../images/rightArrow.png"
import {services} from  "../../data/ServiceData";
import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <>
    <div className='services' id="services">
        {/* header */}
        <div className='service-header'>
            <span className='stroke-text'>Explore</span>
            <span>Our Services</span>
            <span className='stroke-text'>more</span>
        </div>
        <div className='service-catagories '>
            {services.map((service , i)=>{
                return(
                    <div className='category cardeffect' key={i}>
                  <img src=  {service.image} alt="serviceimg"></img>
                    <span>{service.heading}</span>
                    <span>{service.details}</span>

                    <div className='know-more'>
                        <span><Link to ="/servicedetail" style={{ textDecoration: 'none' ,color:"white" }}>Know More</Link></span>
                        <img src={rightarrow} alt="rightarrow"/>
                        </div>
                   
                    </div>
                )
              
            })}

       
        </div>
    </div>
    <About/>
    </>
  )
}

export default Services