import React from "react";
import "./ServiceDetail.css";
import { Header } from "../header/Header";
import Footer from "../footer/Footer";
import sms1 from "../../images/servicedetail/personalization.jpg";
import schedule from "../../images/servicedetail/schedule.jpg";
import api from "../../images/servicedetail/api.jpg";
import report from "../../images/servicedetail/report.jpg";
import sms from "../../images/servicedetail/sms.jpg";
import output from "../../images/servicedetail/output.jpg";
import servicebg from "../../images/servicedetail/servicebg3.png";


const ServiceDetail = () => {
  return (
    <>
      <Header />
      {/* section service */}
      <div className="service-detail-banner col-md-12 col-sm-12">
        <img
          className="img-fluid service-bg "
          src={servicebg}
          alt="servicebanner"
        />
        {/* <div className='service-banner-content details-header' >
<h3 className="text-center w-50">
                we provide seamless WhatsApp API integration services for
                businesses looking to enhance customer communication.
              </h3>
</div> */}
      </div>
      <div className="service-container">
        <div className="our-services mx-auto ">
          <div className="service-circle"></div>
          <div className="row d-flex services-contents">
            <div className="col-lg-6">
              <div
                className="content-service  card shadow mt-3"
                data-aos="fade-right"
              >
                <h5 className="heading-service"> Bulk SMS Campaigns</h5>
                <p className="para-service text-wrap">
                  {" "}
                  Reach thousands or even millions of recipients instantly with
                  our bulk SMS campaigns.
                </p>
                <p className="para-service text-wrap">
                  Whether you're sending promotional offers, announcements,
                  reminders, or alerts, we've got you covered.
                </p>
              </div>
            </div>
            <div className="col-lg-6 service-img">
              <img
                src={sms}
                className="img-fluid img-service"
                data-aos="flip-left"
              />
            </div>
          </div>
        </div>

        {/* bulk sms */}
        <div className="our-services mx-auto ">
          <div className="service-circle"></div>
          <div className="row d-flex services-contents product-reverse">
            <div className="col-lg-6 service-img">
              <img
                src={sms1}
                className="img-fluid img-service"
                data-aos="flip-left"
              />
            </div>
            <div className="col-lg-6">
              <div
                className="content-service card shadow mt-3"
                data-aos="fade-right"
              >
                <h5 className="heading-service  text-break">
                  {" "}
                  Personalization
                </h5>
                <p className="para-service text-wrap">
                  {" "}
                  Customize your messages to make a personalized impact.
                </p>
                <p className="para-service text-wrap">
                  Address recipients by their names and create a connection that
                  resonates.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* personalization */}

        <div className="our-services mx-auto ">
          <div className="service-circle"></div>
          <div className="row d-flex services-contents">
            <div className="col-lg-6">
              <div
                className="content-service  card shadow mt-3"
                data-aos="fade-right"
              >
                <h5 className="heading-service">Scheduling </h5>
                <p className="para-service text-wrap">
                  {" "}
                  Reach thousands or even millions of recipients instantly with
                  our bulk SMS campaigns.
                </p>
                <p className="para-service text-wrap">
                  Plan your SMS campaigns in advance and ensure they're sent at
                  the optimal times for maximum engagement and effectiveness.
                </p>
              </div>
            </div>
            <div className="col-lg-6 service-img">
              <img
                src={schedule}
                className="img-fluid img-service"
                data-aos="flip-left"
              />
            </div>
          </div>
        </div>

        {/* scheduling */}

        {/* delivery report */}

        <div className="our-services mx-auto ">
          <div className="service-circle"></div>
          <div className="row d-flex services-contents  product-reverse">
            <div className="col-lg-6 service-img">
              <img
                src={report}
                className="img-fluid img-service "
                data-aos="flip-left"
              />
            </div>
            <div className="col-lg-6">
              <div
                className="content-service  card shadow mt-3"
                data-aos="fade-right"
              >
                <h5 className="heading-service  text-break">
                  {" "}
                  Delivery Reports
                </h5>
                <p className="para-service text-wrap">
                  {" "}
                  Gain insights into the success of your campaigns with
                  real-time delivery reports.
                </p>
                <p className="para-service text-wrap">
                  Monitor delivery status and assess the impact of your
                  messages.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* api integration */}
        <div className="our-services mx-auto ">
          <div className="service-circle"></div>
          <div className="row d-flex services-contents">
            <div className="col-lg-6">
              <div
                className="content-service  card shadow mt-3"
                data-aos="fade-right"
              >
                <h5 className="heading-service  text-break">
                  {" "}
                  API Integration
                </h5>
                <p className="para-service text-wrap">
                  {" "}
                  Seamlessly integrate our SMS services into your applications,
                  websites, or systems through our user-friendly APIs.
                </p>
              </div>
            </div>
            <div className="col-lg-6 service-img">
              <img
                src={api}
                className="img-fluid img-service"
                data-aos="flip-left"
              />
            </div>
          </div>
        </div>
        <div className="our-services mx-auto ">
          <div className="service-circle"></div>
          <div className="row d-flex services-contents   product-reverse">
            <div className="col-lg-6 service-img">
              <img
                src={output}
                className="img-fluid img-service"
                data-aos="flip-left"
              />
            </div>
            <div className="col-lg-6">
              <div
                className="content-service  card shadow mt-3"
                data-aos="fade-right"
              >
                <h5 className="heading-service  text-break">
                  Opt-out Management
                </h5>
                <p className="para-service text-wrap">
                  {" "}
                  Maintain compliance and respect recipient preferences by
                  easily managing opt-out requests and keeping your
                  communication ethical.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* opt mnagement */}
      </div>

      <Footer />
    </>
  );
};

export default ServiceDetail;
