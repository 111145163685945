
import crm from "../images/product/crm.jpg";
import erp from "../images/product/erp.jpg";
import lab from "../images/product/lab.jpg";
import gst from "../images/product/gst.jpg";
import matrimony from "../images/product/matrimony.jpg";



export const product=[
   {
    image:crm,
    title:"Customer Relationship Management",
    detail:"CRM software is designed to manage interactions with current and potential customers. It focuses on improving customer relationships and enhancing the overall customer experience."
   },
   {
    image:erp,
    title:"Enterprise Resource Planning",
    detail:"ERP software is a comprehensive solution that integrates various business processes and functions into a single system. It enables efficient management of resources across the organization."
   },
   {
    image:lab,
    title:"Laboratory management",
    detail:"Laboratory management software (LIMS - Laboratory Information Management System) is a specialized software designed to streamline and enhance the management of laboratory operations, data, and processes."
   },
   {
      image:gst,
      title:"Gst billing Accounting software",
      detail:"GST (Goods and Services Tax) billing and accounting software are designed to help businesses manage their financial transactions and comply with GST regulations"
     },
     {
      image:matrimony,
      title:"Matrimonial",
      detail:"A matrimonial app is a platform designed to connect individuals seeking life partners for marriageIt facilitates the matchmaking process by allowing users to create profiles, search for potential matches, and communicate with each other"
     }


]