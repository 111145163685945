import React from "react";
import "./About.css";
import Career from "../career/Career";
import development from "../../images/about/development.jpg";
import digital from "../../images/about/digital.jpg";
import sms from "../../images/about/sms.jpg";
import whatsapp from "../../images/about/whatsapp.jpg";
import tick from "../../images/about/tick.png";

const About = () => {
  return (
    <>
      <div className="aboutsession" id="AboutSession">
        <div className="left_a">
          <img src={sms} alt="Bulk sms" className="img-fluid" />
          <img src={development} alt="webdevelopment" className="img-fluid" />
          <img src={digital} alt="digital marketing" className="img-fluid" />
          <img src={whatsapp} alt="whatsapp api" className="img-fluid" />
        </div>
        <div className="right_a">
          <span>About Us</span>
          <div className="details-header">
            <span className="stroke-text">Why</span>
            <span> Choose us</span>
          </div>
          <div className="details-r">
            <div>
              <img src={tick} alt="tick img" className="img-fluid" />
              <span>
                Our team consists of experienced professionals with a passion
                for technology
              </span>
            </div>
            <div>
              <img src={tick} alt="tick img" className="img-fluid" />
              <span>
                Our solutions are tailor-made to address your specific
                challenges and goals.{" "}
              </span>
            </div>
            <div>
              <img src={tick} alt="tick img" className="img-fluid" />
              <span>
                Your success is our priority ensuring transparency throughout
                the project.
              </span>
            </div>
            <div>
              <img src={tick} alt="tick img" className="img-fluid" />
              <span>
                We thrive on innovation and are constantly exploring new
                technologies{" "}
              </span>
            </div>
            <div>
              <img src={tick} alt="tick img" className="img-fluid" />
              <span>
                provide you with state-of-the-art solutions that give you a
                competitive edge.{" "}
              </span>
            </div>
            <div>
              <img src={tick} alt="tick img" className="img-fluid" />
              <span>
                Count on us for timely project delivery, consistent support, and
                a partnership
              </span>
            </div>
          </div>
        </div>
      </div>

      <Career />
    </>
  );
};

export default About;
