import React from 'react'
import "./Contact.css";
import Footer from '../footer/Footer';
const Contact = () => {

  return (

  <>
    <div className="contactsession" id="Contactsession">
      <div className="left-contact">
        <hr/>
        <div>
          <span className="stroke-text">Ready to</span>
          <span> Level up</span>
        </div>
        <div>
          <span> Your Business</span>
          <span className="stroke-text"> With US</span>
        </div>
        
      </div>
     <div className="mb-2 mt-3">
  <iframe style={{border: 0, width: '80%', height: 350}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.6721791610266!2d77.97271067465442!3d10.36807496659384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00ab4ba0a67f8f%3A0xe8c929fa6a6e6e95!2sVirtual%20Technologies!5e0!3m2!1sen!2sin!4v1691038981768!5m2!1sen!2sin" frameBorder={0} allowFullScreen />
</div>{/* End Google Maps */}

    </div>
    <Footer/>
   </>

  );
}


export default Contact