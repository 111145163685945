import React from "react";
import "./Career.css";
import Product from "../product/Product";
import { careerData } from "../../data/careerData";
import whitetick from "../../images/whiteTick.png";
const Career = () => {
  return (
    <>
      <div className="careersession" id="career-section">
        {/* <div className="blur career-blur-1"></div>
      <div className="blur career-blur-2"></div> */}
        <div className="details-header " style={{ textAlign: "center" }}>
          <span className="stroke-text">Ready to start</span>
          <span> your journey</span>
          <span className="stroke-text"> Now withus</span>
        </div>

        {/* careercard */}
        <div className="career">
          {careerData.map((data, i) => {
            return (
              <div className="careercard" key={i}>
                <img src={data.image} alt="img"></img>
                <span>{data.heading}</span>
                <span>Qualification</span>

                <div className="details">
                  <img src={whitetick} alt="tick" />
                  <span>{data.details}</span>
                </div>
                <button className="btns">
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSeJrIVo1uV0qNCxQb4ORyRkBCLow19E7d4x4XVollfUzhAr4A/viewform?usp=sharing">
                    Apply Now
                  </a>
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <Product />
    </>
  );
};

export default Career;
