import React from "react";
import "./Footer.css";
import logo from "../../images/logo.png";
const Footer = () => {
  return (
    <div className="footersession">
      <hr />
      <div className="container mt-3">
        <div className="row gy-3 footer-content " >
          <div className="col-lg-4 col-md-6 d-flex">
            <i className="bi bi-geo-alt icon"></i>
            <div className="footer-details"  >
              <h5>Address</h5>
              <p>
                KC Encalve 2nd floor,Naicker New 1st St <br />
                Govindapuram, Dindigul.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex">
            <i class="bi bi-telephone icon"></i>
            <div className="footer-details">
              <h5>Contact Us</h5>
              <p>
                <strong>Phone:</strong> +91 9597584398
                <br />
                <strong>Email:</strong> info@virtualtechnology.in
                <br />
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 footer-links d-flex">
            <i className="bi bi-clock icon" />
            <div className="footer-details">
              <h5>Opening Hours</h5>
              <p>
                <strong>Mon-Sat: 9.30AM</strong> <strong>- 6.00PM</strong>
                <br />
                Sunday: Closed
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='logo-f mx-auto'>
<img src={logo} alt="logo" className='img-fluid'/>
 </div> */}
      <div className="container">
        <div className="copyright mt-3">
          © Copyright{" "}
          <strong>
            <span style={{ color: "var(--green)" }}>virtualtechnology</span>
          </strong>{" "}
          All Rights Reserved
        </div>
        <div className="credits" />
      </div>
    </div>
  );
};

export default Footer;
