import React from "react";
import "./Hero.css";
import { Header } from "../header/Header";
import {Link} from "react-router-dom"
import Services from "../services/Services";
import heroimg from "../../images/hero/heroimg.png";
import herobg from "../../images/hero/hero_image_back.png";
import { motion } from "framer-motion";
const Hero = () => {
  const tranisition ={type:"spring",duration:3}
  return (
    <>
    <div className="hero ">
      {/* <div className="blur hero-blur"></div> */}
      <div className="left-h">
        <Header style={{padding:"0px"}}/>
        
        <div className="menu-ad">
          <motion.div
          initial={{left:'138px'}}
          whileInView={{left:'8px'}}
          transition={{...tranisition,type:'tween'}}
          ></motion.div>
          <span>Start Your Business with us</span>
        </div>
        {/* hero heading */}
        <div className="hero-txt">
          <div>
            <span className="stroke-text">Start</span>
            <span>  Your </span>
          </div>
          <div>
            <span>Ideal Services</span>
          </div>
          <div>
            <span>
            At Virtual Technology we provide top-notch IT solutions that drive innovation, efficiency, and growth. 
            </span>
          </div>
          {/* hero buttons */}
         
        
        </div>
        <div className="hero-buttons">
          <button className="btns"><a href="#AboutSession" style={{textDecoration:"none" ,color:"white"}}>Get Started  </a></button>
          <button className="btns"><Link to="/productdetail" style={{textDecoration:"none" ,color:"white"}}>Learn More</Link></button>
          </div>
      </div>
      <div className="right-h">
        {/* <div className="btns">Contact Us
        </div> */}
        {/* hero images */}
        <img src={heroimg} alt="heroimg" className="hero-image"/>
        {/* <motion.img 
        initial={{right:"36rem"}}
        whileInView={{right:"26rem"}}
        transition={tranisition} */}
        <img
        src={herobg} alt="backgroundimage" className="hero-image-bg"/>
      </div>
   
    </div>
    <Services/>
    </>
  );
};

export default Hero;
