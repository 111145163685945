import whatsapp from "../images/services/whatsapp.png";
import sms from "../images/services/sms.png";
import digital from "../images/services/digital.png";

export const careerData=[

    
    {
        image:digital,
        heading:"Web Development",
        details:"B.E freshers can also apply"

    },
    {
        image:sms,
        heading:"App Development",
        details:"B.E freshers can also apply"
        
    },
    {
        image:digital,
        heading:"Any Degree",
        details:"Experience: Minimum 2 years"
        
    }
 
]