import React ,{useState}from 'react';
import "./Productdetail.css";
import tick from "../../images/rightArrow.png"
import crm from "../../images/productdetail/crm1.jpg";
import erp from "../../images/productdetail/erp.jpg";
import lab from "../../images/productdetail/lab.jpg";
import gst from "../../images/product/gst.jpg";
import matrimony from "../../images/product/matrimony.jpg";
import productbanner from "../../images/productdetail/productdetail.png"
import { Header } from '../header/Header';
import Footer from '../footer/Footer';
const Productdetail = () => {
const [showcrm,  setShowcrm]=useState(false);
const [showerp,setShowerp]=useState(false);
const[showlab,setShowlab]=useState(false);
const [showgst,setShowgst]=useState(false);
const[showmatrimony,setMatrimony] =useState(false);
const togglecrm = ()=>{
  setShowcrm(!showcrm)
}

const toggleerp =()=>{
setShowerp(!showerp)
}
const togglelab =()=>{
  setShowlab(!showlab)
}

const togglegst =()=>{
  setShowgst(!showgst)
}
const togglematrimony =()=>{
  setMatrimony(!showmatrimony)
}
  return (
    <>
    <Header/>
<div className='service-detail-banner'>
<img className='img-fluid service-bg' src={productbanner} alt="servicebanner"/>
{/* <div className='service-banner-content details-header' >
<h3 className="text-center w-50">
                we provide seamless WhatsApp API integration services for
                businesses looking to enhance customer communication.
              </h3>
</div> */}
</div>
 
      <section id="sec-2" className="py-5">
        <div className="container my-4">
          <div className="product-container">
            <div className="row  d-flex justify-content-between">
              <div className="col-lg-6 mb-5 product-image ">
                <div className='green-content' ></div>
                <div className='green-border'></div>
                <img src={crm} className="img-fluid"/>
              </div>
              <div className="col-lg-6 product-detail1-content">
                <h3>CRM (Customer Relationship Management)</h3>
                <p className="text-white fw-light lh-lg my-4">
                  {" "}
                  CRM (Customer Relationship Management) and ERP (Enterprise
                  Resource Planning) are two different types of software used by
                  businesses to manage various aspects of their operations.
                  Let's briefly explain each one
                </p>

                <div className="product-points">
                  <img src={tick} alt="tick" className="img-fluid" />
                  <span>
                    Contact management: Keeping track of customer information,
                    interactions, and communication history
                  </span>
                </div>
                
               
                    <div className="product-points">
                      <img src={tick} alt="tick" className="img-fluid" />
                      <span>
                        Managing sales processes, leads, and opportunities
                      </span>
                    </div>
                    {showcrm && (
                          <div   className="read-content">
                          <div className="product-points">
                            <img src={tick} alt="tick" className="img-fluid" />
                            <span>
                              Marketing automation: Automating marketing campaigns,
                              lead generation, and customer segmentation.
                            </span>
                          </div>
                          <div className="product-points">
                            <img src={tick} alt="tick" className="img-fluid" />
                            <span>
                              Customer support: Managing customer inquiries, tickets,
                              and support requests.
                            </span>
                          </div>
                          <div className="product-points">
                            <img src={tick} alt="tick" className="img-fluid" />
                            <span>
                              Reporting and analytics: Providing insights into
                              customer behaviour and sales performance.
                            </span>
                          </div>
                          <div className="product-points">
                            <img src={tick} alt="tick" className="img-fluid" />
                            <span>
                              CRM systems help businesses build strong relationships
                              with customers, increase customer retention, and improve
                              sales efficiency.
                            </span>
                          </div>
                        </div>
                    )}
                
             

                {/* readmore button */}
                <button onClick={togglecrm} className="readmore" >
                  {showcrm ? "Read Less":"Read More"}
              
                </button>
              </div>
            </div>
          </div>

          {/* container 1 ends */}

          <div className="product-container">
            <div className="row product-reverse  d-flex justify-content-between">
             
              <div className="col-lg-6 product-detail1-content">
                <h3>ERP (Enterprise Resource Planning)</h3>
                <p className="text-white fw-light">
                  {" "}
                  ERP software is a comprehensive solution that integrates various
                business processes and functions into a single system. It
                enables efficient management of resources across the
                organization. An ERP system centralizes data and provides a
                unified view of the organization, facilitating better
                decision-making, improved efficiency, and reduced operational
                costs.
                </p>

                <div className="product-points">
                  <img src={tick} alt="tick" className="img-fluid" />
                  <span>
                  Finance and accounting: Managing financial transactions,
                  budgeting, and reporting.
                  </span>
                </div>
             
                
                    <div className="product-points">
                      <img src={tick} alt="tick" className="img-fluid" />
                      <span>
                      Human resources: Handling employee information, payroll, and
                  HR processes
                      </span>
                    </div>
                    {showerp && (
                       <div  className="read-content"> 
                       <div className="product-points">
                         <img src={tick} alt="tick" className="img-fluid" />
                         <span>
                         Inventory and supply chain management: Tracking inventory
                     levels, orders, and logistics.
                         </span>
                       </div>
                       <div className="product-points">
                         <img src={tick} alt="tick" className="img-fluid" />
                         <span>
                         Manufacturing and production: Managing production schedules,
                     quality control, and materials
                         </span>
                       </div>
                       <div className="product-points">
                         <img src={tick} alt="tick" className="img-fluid" />
                         <span>
                         Sales and purchasing: Handling procurement, order processing,
                     and vendor management.
                         </span>
                       </div>
                       
                     </div>
                    )}
                 
         
                {/* readmore button */}
                <button onClick={toggleerp} className="readmore" >
                  {showerp ? "Read Less":"Read More"}
              
                </button>

              </div>
               <div className="col-lg-6 mb-5 mt-5 product-image d-flex flex-end">
               <div className='green-content'></div>
                <div className='green-border'></div>
                <img src={erp} className="img-fluid" />
              </div>
            </div>
            
          </div>

          {/* container 2 ends */}

          <div className="product-container">
            <div className="row  d-flex justify-content-between ">
              <div className="col-lg-6 mb-5 product-image ">
                <div className='green-content'></div>
                <div className='green-border'></div>
                <img src={lab} className="img-fluid" />
              </div>
              <div className="col-lg-6 product-detail1-content">
              <h3>Laboratory management</h3>
                <p className="text-white fw-light lh-lg my-4">
                  {" "}
                  Laboratory management software (LIMS - Laboratory Information
                Management System) is a specialized software designed to
                streamline and enhance the management of laboratory operations,
                data, and processes. It is widely used in various industries,
                including healthcare, pharmaceuticals, biotechnology,
                environmental science, and research institutions. The primary
                purpose of LIMS is to improve efficiency, data integrity,
                regulatory compliance, and collaboration within laboratories.
                </p>

                <div className="product-points">
                  <img src={tick} alt="tick" className="img-fluid" />
                  <span>
                  Patient Registration Patient registration with details like
                  MRNo, Name, Address, Reference details and more
                  </span>
                </div>
                
               
                    {showlab && (
                          <div   className="read-content">
                            
                    <div className="product-points">
                      <img src={tick} alt="tick" className="img-fluid" />
                      <span>
                      Lab Bill, Lab result against Lab Bill, Department wise Lab
                  result, Automated solutions management, Store management for
                  chemicals, Cash & credit billing, Lab parameter billing, lab
                  test billing, Lab Test Outsourcing
                      </span>
                    </div>
                          <div className="product-points">
                            <img src={tick} alt="tick" className="img-fluid" />
                            <span>
                            Dot Matrix & Thermal printer, Ink Jet, Laser Printer Settings
                  available with resizable Invoice as per user requirement.
                  Pre-printed, Bill customization.
                            </span>
                          </div>
                          <div className="product-points">
                            <img src={tick} alt="tick" className="img-fluid" />
                            <span>
                            Multiple level user security with User activity monitors to
                  view the activity of each user. User Administration to set
                  permissions for Viewing, Modification, Deletion and Creation
                  for Specific user. Password protection for each user.
                  Automatic Backup feature for data security, Backup and Restore
                  tools, etc.
                            </span>
                          </div>
                         
                         
                        </div>
                    )}
                
             

                {/* readmore button */}
                <button onClick={togglelab} className="readmore" >
                  {showlab ? "Read Less":"Read More"}
              
                </button>
              </div>
            </div>
          </div>
{/* container 3 ends */}

<div className="product-container">
            <div className="row product-reverse  d-flex justify-content-between">
             
              <div className="col-lg-6 product-detail1-content">
              <h3>Gst billing Accounting software</h3>
                <p className="text-white fw-light">
                  {" "}
                  GST (Goods and Services Tax) billing and accounting software are designed to help businesses manage their financial transactions and comply with GST regulations. These software solutions offer a wide range of features to streamline invoicing, accounting, tax filing, and reporting processes. Here are some common features found in GST billing and accounting software
                </p>

                <div className="product-points">
                  <img src={tick} alt="tick" className="img-fluid" />
                  <span>
                  Support for various invoice formats, including tax invoices, bill of supply, credit/debit notes, etc.
                  </span>
                </div>
             
                
                    <div className="product-points">
                      <img src={tick} alt="tick" className="img-fluid" />
                      <span>
                      Inventory Management:Monitoring and tracking stock levels and inventory movement.
                      </span>
                    </div>
                    {showgst && (
                       <div  className="read-content"> 
                       <div className="product-points">
                         <img src={tick} alt="tick" className="img-fluid" />
                         <span>
                         Tax Compliance:Real-time updates for GST rate changes and rules.
                         </span>
                       </div>
                       <div className="product-points">
                         <img src={tick} alt="tick" className="img-fluid" />
                         <span>
                         Bank Reconciliation:Integrating with your bank accounts for seamless reconciliation of transactions.
                         </span>
                       </div>
                       <div className="product-points">
                         <img src={tick} alt="tick" className="img-fluid" />
                         <span>
                         Financial Reports:Comprehensive financial reports like profit and loss statement, balance sheet, cash flow statement, etc.
                         </span>
                       </div>
                       
                     </div>
                    )}
                 
         
                {/* readmore button */}
                <button onClick={togglegst} className="readmore" >
                  {showgst ? "Read Less":"Read More"}
              
                </button>

              </div>
               <div className="col-lg-6 mb-5 mt-5 product-image d-flex flex-end">
               <div className='green-content'></div>
                <div className='green-border'></div>
                <img src={gst} className="img-fluid" />
              </div>
            </div>
            
          </div>

          {/* container 4 ends */}
          <div className="product-container">
            <div className="row  d-flex justify-content-between ">
              <div className="col-lg-6 mb-5 product-image ">
                <div className='green-content'></div>
                <div className='green-border'></div>
                <img src={matrimony} className="img-fluid" />
              </div>
              <div className="col-lg-6 product-detail1-content">
              <h3>Matrimonial</h3>
                <p className="text-white fw-light lh-lg my-4">
                  {" "}
                  A matrimonial app is a platform designed to connect individuals seeking life partners for marriage. It facilitates the matchmaking process by allowing users to create profiles, search for potential matches, and communicate with each other. Here are some common features and a brief description of a matrimonial app
                </p>

                <div className="product-points">
                  <img src={tick} alt="tick" className="img-fluid" />
                  <span>
                  User Registration and Profile Creation:Users can register on the app using their email, phone number, or social media accounts.
                  </span>
                </div>
                        
                <div className="product-points">
                      <img src={tick} alt="tick" className="img-fluid" />
                      <span>
                      Advanced Search Filters:Users can search for potential matches based on criteria like age, religion, community, education, occupation, location, and more
                      </span>
                    </div>
               
                    {showmatrimony && (
                          <div   className="read-content">
                    
                          <div className="product-points">
                            <img src={tick} alt="tick" className="img-fluid" />
                            <span>
                            Match Recommendations: The app uses algorithms to suggest potential matches based on user preferences and compatibility scores.
                            </span>
                          </div>
                          <div className="product-points">
                            <img src={tick} alt="tick" className="img-fluid" />
                            <span>
                            Privacy and Security Settings: Matrimonial apps prioritize user privacy and allow users to control their profile visibility.
                            </span>
                          </div>
                          <div className="product-points">
                            <img src={tick} alt="tick" className="img-fluid" />
                            <span>
                            Media Gallery and Video Profiles:Users can upload photos and videos to showcase their personality and lifestyle.

                            </span>
                          </div>
                          <div className="product-points">
                            <img src={tick} alt="tick" className="img-fluid" />
                            <span>
                            Privacy and Security Settings: Matrimonial apps prioritize user privacy and allow users to control their profile visibility.
                            </span>
                          </div>
                         
                         
                        </div>
                    )}
                
             

                {/* readmore button */}
                <button onClick={togglematrimony} className="readmore" >
                  {showmatrimony ? "Read Less":"Read More"}
              
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      < Footer/>
    </>
  );
}

export default Productdetail